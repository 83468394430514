import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import actions from "rdx/actions";
import Registration from "models/Registration";
import NameAndNumberForm from "./NameAndNumberForm";
import DateOfBirthForm from "./DateOfBirthForm";
import AddressForm from "./AddressForm";
import styles from "./ContactInfo.module.less";

const CONTACT_INFO_STEPS = {
  NameAndNumber: 0,
  Address: 1,
  DateOfBirth: 2,
};

const ContactInfo = ({ regInfo, handleContinue, isAmbassador }) => {
  const [currentStep, setCurrentStep] = useState(
    isAmbassador ? CONTACT_INFO_STEPS.Address : CONTACT_INFO_STEPS.NameAndNumber,
  );
  const [formValues, setFormValues] = useState({});
  const dispatch = useDispatch();

  const continueWithinTab = (v) => {
    setFormValues({ ...formValues, ...v });
    setCurrentStep(currentStep + 1);
  };

  const continueToNextTab = (v) => {
    formValues.dob = `${v.year}-${v.month}-${v.day}`;
    formValues.phone = `${formValues.phonePrefix} ${formValues.phone}`;
    if (!isAmbassador) {
      dispatch(actions.updateRegistration({ ...formValues, slug: regInfo.slug }));
    }
    handleContinue({ dob: formValues.dob });
  };

  const contactInfoFormsMap = [
    { form: <NameAndNumberForm continueToNextStep={continueWithinTab} regInfo={regInfo} /> },
    { form: <AddressForm continueToNextStep={continueWithinTab} regInfo={regInfo} /> },
    { form: <DateOfBirthForm continueToNextStep={continueToNextTab} regInfo={regInfo} /> },
  ];

  return <div className={styles.contactInfoContainer}>{contactInfoFormsMap[currentStep].form}</div>;
};

ContactInfo.propTypes = {
  regInfo: Registration.types(),
  handleContinue: PropTypes.func,
  isAmbassador: PropTypes.bool,
};

export default ContactInfo;
