import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Row, Col, Input, AutoComplete } from "antd";
import { stateOptions } from "lib/misc/dropdownOptions";
import SelectInput from "components/FormElements/SelectInput";
import GradientButton from "components/Buttons/GradientButton";
import { Registration } from "rdx/modules/registration/typings";
import { AddressT } from "models/Address/types";
import { addressLookup } from "lib/helpers";
import styles from "./ContactInfo.module.less";

type AddressFormPropsT = {
  continueToNextStep: (address: AddressT) => void;
  regInfo: Registration;
};

type AddressLookupT = {
  city: string;
  state: string;
  street_line: string;
  zipcode: string;
};

const { Option } = AutoComplete;

const AddressForm = ({ continueToNextStep, regInfo }: AddressFormPropsT) => {
  const { t }: { t: (textRef: string) => string } = useTranslation(["join", "form_labels"]);
  const [form] = Form.useForm();
  const [addressSuggestions, setAddressSuggestions] = useState<JSX.Element[]>([]);

  const onContinue = () => {
    const values = form.getFieldsValue();
    continueToNextStep(values);
  };

  const fetchAddressSuggestions = async (value) => {
    const addresses = await addressLookup(value);

    return setAddressSuggestions(
      addresses.map(
        (suggestion: AddressLookupT, idx: number): JSX.Element => (
          <Option
            // eslint-disable-next-line react/no-array-index-key
            key={`${suggestion.street_line}-${suggestion.city}-${idx}`}
            value={`${suggestion.street_line} ${suggestion.city}, ${suggestion.state} ${suggestion.zipcode}`}
            data={suggestion}
          >
            {suggestion.street_line} {suggestion.city} {suggestion.state}, {suggestion.zipcode}
          </Option>
        ),
      ),
    );
  };

  const handleAddressSelect = (_, option) => {
    form.setFieldsValue({
      street: option.data.street_line,
      city: option.data.city,
      state: option.data.state,
      zip: option.data.zipcode,
    });
  };

  return (
    <div className={styles.onboardingAddressFormContainer} data-test-id="onboarding-address-form">
      <div className={styles.label}>{t("form_labels:address")}</div>
      <Form onFinish={continueToNextStep} form={form} initialValues={regInfo?.address} className={styles.addressForm}>
        <Form.Item
          name="street"
          rules={[
            {
              required: true,
              message: t("form_labels:required_field"),
            },
          ]}
        >
          <AutoComplete
            onSearch={fetchAddressSuggestions}
            onSelect={handleAddressSelect}
            size="large"
            placeholder={t("form_labels:street")}
          >
            {addressSuggestions}
          </AutoComplete>
        </Form.Item>
        <Form.Item name="unit">
          <Input placeholder={t("form_labels:unit")} />
        </Form.Item>

        <Form.Item
          name="city"
          validateTrigger={["onSubmit"]}
          rules={[
            {
              required: true,
              message: t("form_labels:required_field"),
            },
          ]}
        >
          <Input placeholder={t("form_labels:city")} />
        </Form.Item>
        <Row gutter={8}>
          <Col span={12}>
            <SelectInput itemName="state" placeholder={t("form_labels:state")} choices={stateOptions()} />
          </Col>
          <Col span={12}>
            <Form.Item
              name="zip"
              validateTrigger={["onSubmit"]}
              rules={[
                {
                  required: true,
                  message: t("form_labels:required_field"),
                },
              ]}
            >
              <Input placeholder={t("form_labels:zip")} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldsValue }) => {
            const { street, city, state, zip } = getFieldsValue();
            const formIsComplete = !!street && !!city && !!state && !!zip;
            return (
              <GradientButton
                type="primary"
                onClick={onContinue}
                className={styles.contactInfoSubmit}
                disabled={!formIsComplete}
              >
                {t("join_form.continue")}
              </GradientButton>
            );
          }}
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddressForm;
